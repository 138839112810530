@blue1:#111f53;
@blue2:#193889;
@yellow:#fccf00;
html,
body {
	margin: 0;
}
textarea {
	resize: vertical;
}
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
a {
	color: @yellow;
	text-decoration: underline;

	&:hover {
		color: lighten(@yellow, 20%);
	}
}
.clear {
	clear: both;
}
.clearfix {
	&:before,
	&:after {
		content: " "; // 1
		display: table; // 2
	}
	&:after {
		clear: both;
	}
}

.img-responsive {
	max-width: 100%;
	height: auto;
	display: block;
}

body {
	font-family: "EB Garamond", times, serif;
	font-size: 17px;
	color: white;
}
h1, h2, h3, h4, h5, h6 {
	font-size: 32px;
	color: #fff;
	line-height: 1.4em;
}

p {
	line-height: 1.2em;
	margin: 0 0 12px 0;
}
#container {background: @blue2;}
.container {
  	position: relative;
	max-width: 968px;
	margin: 0 auto;
}
.block-blue-2 {
	background: @blue1;
}
.text-center {
	text-align: center;
}
h2.section-title {
	text-align: center;
	margin: 0 0 35px 0;
}
.site-footer,
.site-header {
  	background: @blue1;
	.header-mobile,
	.back {
		width: 100%;
	}
}
.site-footer {
  background:  url(../../img/bg-footer.jpg) center bottom 4px repeat-x;
}
.site-header {
	position: relative;

	.header-mobile {
		display: block;
	}

	.header-desktop {
		display: none;
	}

	.dialog {
		top: 20px;
		left: 200px;
		position: absolute;
	}

	@media screen and (min-width: 968px) {
		.header-mobile {
			display: none;
		}
		.header-desktop {
			display: block;
		}
	}
}
.intro-section {
	position: relative;

	.penguin {
		display: none;
		position: absolute;
	}
	.penguin1 {
		top: 30px;
		left: 50px;
	}
	.penguin2 {
		top: 30px;
		right: 50px;
	}
	@media screen and (min-width:968px) {
		.penguin {
			display: block;
		}
	}
}
.section-padding,
.intro-section,
.penguins-section,
.huddle-section {
	padding: 20px 12px 10px 12px;
	@media screen and (min-width: 768px) {
		padding: 50px 35px 30px 35px;
	}
}
.huddle {
	padding: 0;
	margin: 0;

	li {
		list-style: none;
		margin-bottom: 50px;
	}

	.frame {
		width: 280px;
		height: 280px;
		border-radius: 50%;
		background: @blue2;
		padding: 105px 0;
		margin: 0 auto;

		img {
			display: block;
			max-width: 100%;
			margin: 0 auto !important;
		}
	}

	.pack-name,
	.pack-description {
		text-align: center;
	}
	.pack-name {
		margin: 10px 0;
		font-size: 30px;
	}

	@media screen and (min-width:768px) {
		li {
			width: 33%;
			float: left;
			margin-bottom: 0;
		}
	}

	@media screen and (min-width:768px) and (max-width: 967px) {
		.frame {
			width: 200px;
			height: 200px;
			padding: 80px 0;
		}
	}

}

.penguins {
	padding: 0;
	margin: 20px 0;
	text-align: center;

	li {
		list-style: none;
		display: inline-block;
		margin: 0 10px 0 0;

		&:last-child {
			margin-right: 0;
		}

		&:hover {
			.frame img.anim1 {
				transform: scale(1.15) rotate(-3deg);
			}
			.frame img.anim2 {
				transform: scale(1.05) rotate(10deg);
			}
			.frame img.anim3 {
				transform: scale(1.2) rotate(5deg);
			}
		}
	}

	@media screen and (max-width:480px) {
		li {
			display: block;
			margin: 0 10px 20px 0;

			.frame {
				margin: 0 auto;
			}
		}
	}

	.frame {
		background: white;
		padding: 10px 0;
		width: 130px;
		text-align: center;

		img {
			transition: 0.35s all;
		}
	}
	.label {
		text-align: center;
		padding: 8px 0;
	}
	a {
		display: block;
		color: white;
		text-decoration: none;
	}
}

.form-section {
	color: white;

	.strip {
		height: 7px;
		margin-top: 0;
	}
	h2 {
		color: white;
		margin: 0;
		padding: 25px 0 20px;
	}

	.inner {
		background: #c2af96;
		padding: 0 12px;
	}

	@media screen and (min-width: 768px) {
		.inner {
			padding: 0 50px;
		}
		.col-right,
		.col-left {
			width: 50%;
			float: left;
		}
	}
}


.form-section {
	@media screen and (min-width: 968px) {
		.form-submit {
			padding-left: 142px;
		}
	}
}

form {
	.form-group {
		margin: 10px 0;
	}
	label {
		display: inline-block;
		width: 130px;
	}
	select,
	input[type="email"],
	input[type="text"] {
		font-family: "EB Garamond", times, serif;
		display: inline-block;
		padding: 3px;
		border: 1px solid #DDD;
		border-radius: 0;
		height: 38px;
		width: 100%;
		font-size: 13px;
	}
	select {
		border-radius: 0 !important;
		height: 38px;
	}

	@media screen and (max-width: 967px) {
		label {
			width: 100%;
		}
	}

	@media screen and (min-width: 768px) {
		select,
		input[type="email"],
		input[type="text"] {
			width: 265px;
		}
		select {
			width: 266px;
		}

	}

	.terms {
		padding-right: 14px;
		position: relative;

		label {
			padding: 6px 0;
			width: 100%;
			font-size: 12px;
		}
	}

	.submit {
		cursor: pointer;
		outline: none !important;
		background: @yellow;
		color: @blue2;
		border: 1px solid @yellow;
		border-radius: 0;
		text-transform: uppercase;
		font-size: 22px;
		padding: 10px;
		width: 100%;
		transition: 0.5s all;

		&:hover {
			background: white;
			color: @blue1;
		}
	}

	@media screen and (min-width: 768px) {
		label {
			margin-right: 10px;
		}
		.submit {
			width: 265px;
		}
	}
}

.thanks-message {
	padding: 15px 50px 50px 50px;
	text-align: center;
	p {
		font-size: 30px;
		margin: 0;
		line-height: 1.4em;
	}
}
.form-message {
	font-size: 15px;
	padding: 8px 10px;
	color: #ff1d2b;

	&.error-message {
		background-color: #fefffd;
		border: 1px solid #ff1d2b;
	}
}

.ng-submitted .ng-invalid,
.ng-touched.ng-invalid {
	border: 1px solid #ff1d2b;
	color: #ff1d2b;
	background-color: rgb(254, 255, 253);
}

.fader.ng-enter {
	transition:0.5s linear all;
	opacity:0;
}

/* The finishing CSS styles for the enter animation */
.fader.ng-enter.ng-enter-active {
	opacity:1;
}